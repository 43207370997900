import { useState } from 'react'

export const useLocalStorage = (key: string, defaultValue: any | null) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const value = localStorage.getItem(key)
			if (value) {
				return JSON.parse(value)
			} else {
				localStorage.setItem(key, JSON.stringify(defaultValue))
				return defaultValue
			}
		} catch (_err) {
			return defaultValue
		}
	})
	const setValue = (newValue: any | null) => {
		try {
			localStorage.setItem(key, JSON.stringify(newValue))
		} catch (_err) {
			localStorage.setItem(key, newValue)
		}
		setStoredValue(newValue)
	}
	return [storedValue, setValue]
}
